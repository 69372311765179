export function pipe(value) {
    var operations = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        operations[_i - 1] = arguments[_i];
    }
    var ret = value;
    var lazyOps = operations.map(function (op) {
        var _a = op, lazy = _a.lazy, lazyArgs = _a.lazyArgs;
        if (lazy) {
            var fn = lazy.apply(void 0, lazyArgs);
            fn.indexed = lazy.indexed;
            fn.single = lazy.single;
            fn.index = 0;
            fn.items = [];
            return fn;
        }
        return null;
    });
    var opIdx = 0;
    while (opIdx < operations.length) {
        var op = operations[opIdx];
        var lazyOp = lazyOps[opIdx];
        if (!lazyOp) {
            ret = op(ret);
            opIdx++;
            continue;
        }
        var lazySeq = [];
        for (var j = opIdx; j < operations.length; j++) {
            if (lazyOps[j]) {
                lazySeq.push(lazyOps[j]);
                if (lazyOps[j].single) {
                    break;
                }
            }
            else {
                break;
            }
        }
        var acc = [];
        for (var j = 0; j < ret.length; j++) {
            var item = ret[j];
            if (_processItem({ item: item, acc: acc, lazySeq: lazySeq })) {
                break;
            }
        }
        var lastLazySeq = lazySeq[lazySeq.length - 1];
        if (lastLazySeq.single) {
            ret = acc[0];
        }
        else {
            ret = acc;
        }
        opIdx += lazySeq.length;
    }
    return ret;
}
function _processItem(_a) {
    var item = _a.item, lazySeq = _a.lazySeq, acc = _a.acc;
    if (lazySeq.length === 0) {
        acc.push(item);
        return false;
    }
    var lazyResult = { done: false, hasNext: false };
    var isDone = false;
    for (var i = 0; i < lazySeq.length; i++) {
        var lazyFn = lazySeq[i];
        var indexed = lazyFn.indexed;
        var index = lazyFn.index;
        var items = lazyFn.items;
        items.push(item);
        lazyResult = indexed ? lazyFn(item, index, items) : lazyFn(item);
        lazyFn.index++;
        if (lazyResult.hasNext) {
            if (lazyResult.hasMany) {
                var nextValues = lazyResult.next;
                for (var _i = 0, nextValues_1 = nextValues; _i < nextValues_1.length; _i++) {
                    var subItem = nextValues_1[_i];
                    var subResult = _processItem({
                        item: subItem,
                        acc: acc,
                        lazySeq: lazySeq.slice(i + 1),
                    });
                    if (subResult) {
                        return true;
                    }
                }
                return false;
            }
            else {
                item = lazyResult.next;
            }
        }
        if (!lazyResult.hasNext) {
            break;
        }
        // process remaining functions in the pipe
        // but don't process remaining elements in the input array
        if (lazyResult.done) {
            isDone = true;
        }
    }
    if (lazyResult.hasNext) {
        acc.push(item);
    }
    if (isDone) {
        return true;
    }
    return false;
}
