import { purry } from './purry';
/**
 * Splits a collection into sets, grouped by the result of running each value through `fn`.
 * @param fn the grouping function
 * @signature
 *    R.groupBy(fn)(array)
 * @example
 *    R.pipe(['one', 'two', 'three'], R.groupBy(x => x.length)) // => {3: ['one', 'two'], 5: ['three']}
 * @data_last
 * @indexed
 * @category Array
 */
export function groupBy() {
    return purry(_groupBy(false), arguments);
}
var _groupBy = function (indexed) { return function (array, fn) {
    var ret = {};
    array.forEach(function (item, index) {
        var value = indexed ? fn(item, index, array) : fn(item);
        var key = String(value);
        if (!ret[key]) {
            ret[key] = [];
        }
        ret[key].push(item);
    });
    return ret;
}; };
(function (groupBy) {
    function indexed() {
        return purry(_groupBy(true), arguments);
    }
    groupBy.indexed = indexed;
})(groupBy || (groupBy = {}));
