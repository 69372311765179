import { purry } from './purry';
export function chunk() {
    return purry(_chunk, arguments);
}
function _chunk(array, size) {
    var ret = [];
    var current = null;
    array.forEach(function (x) {
        if (!current) {
            current = [];
            ret.push(current);
        }
        current.push(x);
        if (current.length === size) {
            current = null;
        }
    });
    return ret;
}
